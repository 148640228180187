import React from 'react'

import './Stream.css'

const Work1 = require('../images/work/work1.png')
const Work2 = require('../images/work/work2.png')
const Work3 = require('../images/work/work3.png')
const Work4 = require('../images/work/work4.png')
const Work5 = require('../images/work/work5.png')
const Work6 = require('../images/work/work6.png')
const Work7 = require('../images/work/work7.png')

const StreamPage = () => (
  <div className="stream">
    <div className="row end">
      <img src={Work1} className="stream-img-work1" alt="Our Work - Cherry" />
    </div>
    <div className="column">
      <img src={Work2} className="stream-img-work2" alt="Our Work - Cherry" />
      <img src={Work3} className="stream-img-work3" alt="Our Work - Cherry" />
    </div>
    <div className="row start">
      <img src={Work4} className="stream-img-work4" alt="Our Work - Cherry" />
    </div>
    <div className="row end">
      <img src={Work5} className="stream-img-work5" alt="Our Work - Cherry" />
    </div>

    <div className="row start">
      <img src={Work6} className="stream-img-work6" alt="Our Work - Cherry" />
    </div>
    <div className="row end">
      <img src={Work7} className="stream-img-work7" alt="Our Work - Cherry" />
    </div>
  </div>
)

export default StreamPage
